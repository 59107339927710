<template>
    <section>
        <modal ref="modalInactivarTienda" :titulo="`Inactivar ${$config.vendedor}`" tamano="modal-xl" no-aceptar adicional="Inactivar" @adicional="cambiar_estado">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center mt-1 mb-3">
                    <div class="col-5">
                        <div class="row mx-0">
                            <div class="col-12 mb-2 d-middle pl-4 text-general">
                                <img :src="tienda.logo_mini_firmado" class="obj-cover br-6 border" height="36" width="36" />
                                <div class="col-auto f-18 f-500">
                                    {{ tienda.propietario_nombre }}
                                </div>
                            </div>
                            <div class="col-12 bg-fondo border br-6 px-3 py-2">
                                <p class="text-general f-15">
                                    <b class="f-30">{{ clientesCount }}</b> {{ $config.cliente + 's' }} del {{ $config.vendedor }}
                                </p>
                                <p class="text-general f-15 ml-3">
                                    <b class="f-30">{{ principalCount }}</b> {{ $config.cliente + 's' }} como {{ $config.vendedor }} principal
                                </p>
                            </div>
                            <div v-if="principalCount > 0" class="col-12 px-0 mt-3">
                                <p class="text-general mb-2 f-14">
                                    Seleccione un {{ $config.vendedor }} para que reemplace este como {{ $config.vendedor }} principal
                                </p>
                                <div class="row mx-0 align-items-center">
                                    <div class="rounded-circle text-white d-middle-center bg-general2" style="width:38px;height:38px;">
                                        <i v-if="!url_remplazo" class="icon-leechero-cap f-18" />
                                        <img v-else-if="url_remplazo != ''" :src="url_remplazo" class="obj-cover rounded-circle" width="100%" height="100%" />
                                    </div>
                                    <div class="col">
                                        <el-select v-model="cambioLeechero" placeholder="Leechero" class="w-100" size="small" filterable @change="setImageLec">
                                            <el-option
                                            v-for="item in leecheros"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="row mx-0">
                            <p class="col-12 text-general f-15">
                                Indique por qué se inactiva este {{ $config.vendedor }}
                            </p>
                            <div class="col-12 mt-3">
                                <p class="text-general px-2 f-12">
                                    Motivo de inactividad
                                </p>
                                <div style="width: 400px;">
                                    <ValidationProvider v-slot="{errors}" rules="required" name="motivo">
                                        <el-select v-model="model.id_motivo" placeholder="Motivo" size="small" class="w-100">
                                            <el-option
                                            v-for="item in motivos"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 py-2" />
                            <div class="col-12">
                                <p class="text-general px-2 f-12">
                                    Justificación
                                </p>
                                <div style="width: 400px;">
                                    <ValidationProvider v-slot="{errors}" rules="required|max:1000" name="justificacion">
                                        <el-input v-model="model.justificacion" placeholder="Justificación" size="small" class="w-100" type="textarea" :rows="5" maxlength="1000" show-word-limit />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 text-center">
                        Este leechero tiene {{ clientesCount }} {{ clientesCount == 1 ? 'cliente' : 'clientes' }} <br /> {{ principalCount }} {{ principalCount == 1 ? 'cliente principal' :'clientes principales' }}
                    </div> -->
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import { mapGetters} from 'vuex'
import CambioEstado from "~/services/tiendas/cambio_estados";
export default {
    data(){
        return{
            motivos: [],
            url_persona: 'https://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg?size=626&ext=jpg',
            model: {
                id: null,
                id_motivo: null,
                justificacion: null,
                tipo:6
            },
            principalCount: 0,
            clientesCount: 0,
            leecheros: [],
            cambioLeechero: '',
            url_remplazo: null,
        }
    },
    computed: {
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
    },
    methods: {
        async toggle(){
            this.limpiar()
            await this.leecheroClientes()
            await this.get_motivos()
            this.$refs.modalInactivarTienda.toggle();
        },
        async get_motivos(){
            try {
                const {data} = await CambioEstado.get_motivos(1)
                this.motivos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiar_estado(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid) return this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                if (this.principalCount > 0 && !this.cambioLeechero) return this.notificacion('', 'Debe seleccionar un leechero', 'warning')

                this.model.id = this.$route.params.id_tienda

                this.model.cambioLeechero = this.cambioLeechero
                const {data} = await CambioEstado.cambiar_estado(this.model)
                this.notificacion('Mensaje','Leechero inactivada correctamente','success')
                this.$refs.modalInactivarTienda.toggle();
                this.$refs.validacion.reset()
                this.$router.push({ name: 'admin.tiendas.inactivos.informacion', params: { id_tienda: this.$route.params.id_tienda } })
            } catch (e){
                this.error_catch(e)
            }
        },
        async leecheroClientes(){
            try {
                const { data } = await CambioEstado.leecheroClientes(this.$route.params.id_tienda);

                this.principalCount = data.principal_count
                this.clientesCount = data.clientes_count
                this.leecheros = data.leecheros

            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.motivos = []
            this.model = {
                id: null,
                id_motivo: null,
                justificacion: null,
                tipo:6
            }
            this.principalCount = 0
            this.clientesCount = 0
            this.leecheros = []
            this.cambioLeechero = ''
            this.$refs.validacion.reset()
        },
        setImageLec(){
            this.leecheros.forEach((el) => {
                if(el.id == this.cambioLeechero){
                    this.url_remplazo = el.imagen_mini;
                    console.log(this.url_persona);
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.rounded-user{
    width: 38px;
    height: 38px;
    background-color: #707070;
    color: #FFFFFF;
    border-radius: 50%;
}
</style>
